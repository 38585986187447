@import "~toastr/toastr";

#toast-container > div {
  opacity: 1 !important;
  width: 100%;
  min-width: 300px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

#toast-container > .toast-success {
  background-image: url('../assets/images/icon_success.svg') !important;
  background-repeat: no-repeat;
  background-color: #D4EDDA !important;
  color: #155724 !important;
  border: 1px solid #155724;
  .toast-close-button {
    position: absolute;
    top: -5px;
    right: 5px;
    color: #155724 !important;
  }
}

#toast-container > .toast-error {
  background-image: url('../assets/images/icon_error.svg') !important;
  background-repeat: no-repeat;
  background-color: #F8D7DA !important;
  color: #721c24 !important;
  border: 1px solid #721c24;
  .toast-close-button {
    color: #721c24 !important;
    position: absolute;
    top: -5px;
    right: 5px;

  }
}

#toast-container > .toast-info {
  background-image: url('../assets/images/icon_info.svg') !important;
  background-repeat: no-repeat;
  background-color: #D1ECF1 !important;
  color: #31708F !important;
  border: 1px solid #31708F;
  .toast-close-button {
    color: #31708F !important;
    position: absolute;
    top: -5px;
    right: 5px;
  }
}

#toast-container > .toast-warning {
  background-image: url('../assets/images/icon_warning.svg') !important;
  background-repeat: no-repeat;
  background-color: #FFF3CD !important;
  color: #8a6d3b !important;
  border: 1px solid #8a6d3b;
  .toast-close-button {
    color: #8a6d3b !important;
    position: absolute;
    top: -5px;
    right: 5px;
  }
}
