@import "../../styles/responsive";

.page-body {
  .homePage {
    .ant-table {
      background: none;
      border-radius: 0;
      table {
        border-spacing: 0 8px;
        tr {
          background: #d0d59a;
          a {
            display: block;
            font-weight: 500;
          }
        }
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td,
      .ant-table tfoot > tr > th,
      .ant-table tfoot > tr > td {
        position: relative;
        padding: 11px 11px;
        overflow-wrap: break-word;
        border: 0;
      }
      thead.ant-table-thead {
        display: none;
      }
    }
    @include breakpoint($desktop) {
      .ant-col {
        margin-bottom: 0;
      }
    }
    @include breakpoint($tablet) {
      .ant-col {
        margin-bottom: 0;
      }
    }
    @include breakpoint($mobileOnly) {
      .ant-col {
        margin-bottom: 0;
      }
    }
  }
}
.homePage {
  .eventsInfo {
    .eventActiveRow {
      text-align: left;
    }
    h4 {
      color: #fff;
    }
  }
  .userHide {
    display: none;
  }
}
