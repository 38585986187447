@import "../../../styles/colours";
@import "../../../styles/responsive";
.MainMenu {
  .ant-btn {
    &.toggleBtn {
      float: right;
      top: 5px;
      font-size: 20px;
      background-color: transparent !important;
      box-shadow: none;
      cursor: pointer;
      border: 0 !important;
      display: none;
    }
  }
  @include breakpoint($tablet) {
    .ant-btn {
      &.toggleBtn {
        display: inline-block;
      }
    }
    .ant-menu-horizontal {
      display: none !important;
    }
  }
  @include breakpoint($mobileOnly) {
    .ant-btn {
      &.toggleBtn {
        display: inline-block;
      }
    }
    .ant-menu-horizontal {
      display: none !important;
    }
  }
}

.ant-menu-horizontal > .ant-menu-submenu.matatiniSubMenu {
  color: #fff !important;
  background-color: #00a8b0;
  min-width: 110px;
  display: block;
  text-align: center;
  line-height: 36px;
  border-radius: 40px;
  margin-left: 5px;
}
.ant-menu-horizontal > .ant-menu-submenu.matatiniSubMenu:hover {
  color: #fff;
  background-color: #a1ab36;
  span {
    color: #fff !important;
  }
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #00a8b0 !important;
  .ant-menu-item a {
    color: #fff !important;
  }
  .ant-menu-item a:hover {
    color: #ffffff !important;
  }
  .ant-menu-item:hover,
  .ant-menu-item-active {
    color: #fff;
    background-color: #a1ab36;
  }
}
.ant-menu-item-group-list li {
  padding: 0 15px !important;
}
.ant-menu-item-group-title {
  color: #fff !important;
  font-weight: 500;
  border-bottom: 1px solid #029299;
}
