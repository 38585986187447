@import "../../styles/responsive";

.commentsInfo {
  .ant-col {
    margin-bottom: 15px;
  }
  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  @include breakpoint($desktop) {
    .ant-col {
      margin-bottom: 15px;
    }
  }
  @include breakpoint($tablet) {
    .ant-col {
      margin-bottom: 100px;
    }
  }
  @include breakpoint($mobileOnly) {
    .ant-col {
      margin-bottom: 100px;
    }
  }
}
.ant-form-item-label > label {
  font-weight: 600;
}

.addCommentModal {
  .ant-modal-header {
    padding: 0px 0px;
    border-bottom: 0px solid #f0f0f0;
  }
  .modalHeadingSteps {
    background-color: #fafafa;
    border-radius: 20px 20px 0 0;
    .ant-steps-item-icon {
      display: none;
    }
    .ant-steps-item-title::after {
      display: none;
    }
    .ant-steps-item {
      width: 50%;
      padding: 10px !important;
      text-align: center;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      color: rgba(0, 0, 0, 0.45);
    }
  }
  .teamsTab {
    thead.ant-table-thead tr th:last-child {
      text-align: center;
    }
    .rowDelete {
      display: block;
      text-align: center;
    }
  }
  .ant-radio-group {
    width: 100%;
  }
  .ant-space {
    width: 100%;
  }
  .ant-space-item {
    display: block;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    padding: 5px 0 15px;
    &:last-child {
      border-bottom: 0px solid #f2f2f2;
    }
  }
  .ant-checkbox-group {
    display: block;
  }
  .ant-checkbox-wrapper {
    margin: 0 !important;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    padding: 12px 0 12px;
    &:last-child {
      border-bottom: 0px solid #f2f2f2;
    }
  }
}
ul.selectedDisciplines {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding: 10px;
    border-bottom: 1px solid #f2f2f2;
    span:last-child {
      float: right;
      top: 3px;
      position: relative;
      color: #000;
    }
  }
}
a.AddDisciplines {
  color: #a1ab36;
  font-weight: 600;
  display: inline-block;
  margin: 20px 0 0;
  border: 0px solid #a1ab36;
  padding: 7px 7px;
  border-radius: 20px;
}
