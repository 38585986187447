@import "./colours";
.placeholder-container {
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  display: flex;
}
.tree-ref-data {
  padding: 0;
}

.modal-listing {
  padding: 0;
  .no-data {
    font-size: 20px;
    text-align: center;
    padding: 30px 0;
    color: $grey-2;
  }
  .ant-modal-content {
    .ant-modal-body {
      .flc-blank {
        cursor: pointer;
        border-bottom: 1px solid $grey-5;
        padding: 0 0 20px;
        color: $primary-blue;
        margin-bottom: 20px;
        svg {
          height: 16px;
          width: 16px;
          margin: 0 10px 0;
        }
      }
      .template-grp {
        .list-group {
          .list-group-item {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            span {
              display: flex;
              justify-content: flex-start;
              margin: 0;
              color: $primary-blue;
              font-size: 0.9rem;
              svg {
                height: 1rem;
                width: 1rem;
                margin: 0 10px 0;
              }
            }
            small {
              color: $grey-2;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      button {
        border-bottom-right-radius: 10px !important;
      }
    }
  }
}

::-webkit-input-placeholder {
  /* Chrome */
  color: #ccc;
}
.selectable-label {
  margin-left: 20px !important;
  padding: 4px 0;
}

.form-control,
.custom-file-control,
label {
  line-height: 1 !important;
}

.ant-input::placeholder {
  line-height: 22px;
}

.mt-2::placeholder {
  line-height: 26px;
}
.survey-custom-modal {
  input::placeholder {
    line-height: 2;
  }
}

.sv_q_file {
  input[type="file"] {
    min-height: 35px;
    padding-left: 0 !important;
  }
}

.ant-table-wrapper {
  .ant-table-content {
    .ant-table-body {
      overflow-y: auto !important;
    }
  }
}


