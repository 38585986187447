$black: #000000;
$grey-1: #666666;
$grey-2: #999999;
$grey-3: #CCCCCC;
$grey-4: #D9D9D9;
$grey-5: #F1F1F1;
$white: #FFFFFF ;

$primary-blue: #00A1DE;
$primary-blue-dark: #005986;
$primary-blue-light: #81D5F9;
$primary-blue-lighter: #CBEDFC;
$primary-blue-disable:#D3EEF9;
$primary-green:#a1ab36 ;

$secondary-red: #FF0000;
$secondary-yellow: #FFC200;
$secondary-teal: #00a8b0;
$secondary-green: #d0d59a;
$secondary-blue: #0076FF;
$secondary-purple: #C000D9;
$secondary-slate: #D5D6E3;
$secondary-slate-light: #E9EBF4;
$secondary-orange: #FF7701;

$Third-green:#e8eacd ;

$orange-1: #FFC107;


$link-hover-colour: rgba(203, 237, 252, 0.3);

.secondary-teal {
  color: $secondary-teal;
}

.grey-4 {
  color: $grey-4;
}

.bg-grey-4 {
  background-color: $grey-4;
}

.text-red {
  color: $secondary-red !important;
}
