.TeamsInfo {
  .ant-col {
    margin-bottom: 15px;
  }
  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .userActiveRow {
    text-align: right;
    .ant-radio-button-wrapper {
      line-height: 30px !important;
    }
  }
  .userShow {
    display: table-row;
  }
  .userHide {
    display: none;
  }
}
