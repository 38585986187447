@import "../../styles/responsive";

.UsersInfo {
  .ant-col {
    margin-bottom: 15px;
  }
  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .userActiveRow {
    text-align: right;
    .ant-radio-button-wrapper {
      line-height: 30px !important;
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #fff;
      background: #a1ab36;
      border-color: #a1ab36;
    }
    .ant-radio-button-wrapper {
      color: #fff;
      background: #00a8b0;
      border: 1px solid #00a8b0;
    }
  }
  @include breakpoint($desktop) {
    .ant-col {
      margin-bottom: 15px;
    }
  }
  @include breakpoint($tablet) {
    .ant-col {
      margin-bottom: 15px;
    }
  }
  @include breakpoint($mobileOnly) {
    .ant-col {
      margin-bottom: 15px;
    }
    thead.ant-table-thead {
      display: none;
    }
    .ant-table-tbody > tr > td {
      width: 100%;
      display: block;
      padding-left: 25%;
    }
    td.ant-table-cell:nth-child(3n + 1):before {
      content: "Name";
      padding: 0 5px;
      margin-right: 5px;
      position: absolute;
      left: 0;
      font-weight: 600;
    }
    td.ant-table-cell:nth-child(3n + 2):before {
      content: "Email";
      padding: 0 5px;
      margin-right: 5px;
      position: absolute;
      left: 0;
      font-weight: 600;
    }
    td.ant-table-cell:nth-child(3n + 3):before {
      content: "Admin";
      padding: 0 5px;
      margin-right: 5px;
      position: absolute;
      left: 0;
      font-weight: 600;
    }
    td.ant-table-cell:nth-child(3n + 3) {
      border-bottom: 15px solid #f2f2f2;
    }
  }
  .userShow {
    display: table-row;
  }
  .userHide {
    display: none;
  }
}
.ant-form-item-label > label {
  font-weight: 600;
}
.signatureBox {
  margin: 20px 0 10px;
  .signatureBoxProfile {
    position: relative;
    label {
      position: absolute;
      left: 0px;
      background-color: #d0d59a;
      width: 170px;
      text-align: center;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #f2f2f2;
    }
    input {
      position: relative;
      z-index: 1;
      opacity: 0;
    }
  }
  .signatureBoxDisplayProfile {
    margin: 10px 0;
    width: 100%;
    border: 2px solid #f2f2f2;
    img {
      width: 100%;
      max-width: 250px;
    }
  }
  input[type="file"]::-ms-browse {
    border: 2px solid #f2f2f2;
    padding: 0.5em 0.4em;
    border-radius: 0.2em;
    background-color: #d0d59a;
    width: 160px;
  }

  input[type="file"]::-webkit-file-upload-button {
    border: 2px solid #f2f2f2;
    padding: 0.5em 0.4em;
    border-radius: 0.2em;
    background-color: #d0d59a;
    transition: 1s;
    width: 160px;
  }

  input[type="file"]::file-selector-button {
    border: 2px solid #f2f2f2;
    padding: 0.5em 0.4em;
    border-radius: 0.2em;
    background-color: #d0d59a;
    transition: 1s;
    width: 160px;
  }

  input[type="file"]::-ms-browse:hover {
    background-color: #d0d59a;
    border: 2px solid #f2f2f2;
  }

  input[type="file"]::-webkit-file-upload-button:hover {
    background-color: #d0d59a;
    border: 2px solid #f2f2f2;
  }

  input[type="file"]::file-selector-button:hover {
    background-color: #d0d59a;
    border: 2px solid #f2f2f2;
  }
}
