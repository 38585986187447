@import "../../../styles/responsive";

.action-container {
  background-color: #e8eacd;
  border-top: 0px solid #d9d9d9;
  padding: 16px;
  h4 {
    color: #fff;
  }
  .ant-table {
    background: none;
    border-radius: 0;
    table {
      border-spacing: 0 8px;
      tr {
        background: #d0d59a;
        a {
          display: block;
          font-weight: 500;
        }
      }
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      position: relative;
      padding: 11px 11px;
      overflow-wrap: break-word;
      border: 0;
    }
    thead.ant-table-thead {
      display: none;
    }
  }
  .teamDisciplines {
    .ant-collapse {
      border: 0;
      background: transparent;
      .ant-collapse-item {
        border-bottom: 0px solid #0a0a0a;
        margin-bottom: 8px;
      }
    }
    .ant-collapse-header {
      background: #d0d59a;
      font-weight: 500;
    }
    .ant-collapse-content {
      background-color: #e8eacd;
      border-top: 0px solid #d9d9d9;
    }
    .ant-steps-item {
      width: 50%;
    }
    .ant-steps {
      background: #d5d7be;
      padding: 7px;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
      background: #a1ab36;
    }
    .ant-steps-item-process .ant-steps-item-icon {
      background-color: #fff;
      border-color: #a1ab36;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      color: #a1ab36;
    }
    .ant-steps-item-finish .ant-steps-item-icon {
      border-color: #a1ab36;
    }
    .steps-content {
      min-height: 121px;
      padding: 15px 0;
      .ant-checkbox-group {
        margin-bottom: 15px;
        .ant-checkbox-wrapper {
          display: inline-flex;
          width: 100%;
          justify-content: left;
          padding: 10px 0;
          margin: 0;
          border-bottom: 1px solid #d5d7be;
        }
      }
      label.ant-checkbox-wrapper {
        display: inline-flex;
        width: 100%;
        justify-content: left;
        padding: 5px 0 11px 0;
        margin: 0px 0 20px 0;
        border-bottom: 1px solid #d5d7be;
      }
      .ant-input-number input {
        cursor: not-allowed;
      }
      .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled span {
        color: #000;
      }
      .error-message-aconex {
        position: absolute;
        color: #ff0000;
        font-size: 11px;
      }
    }
    .steps-action {
      button.ant-btn {
        border-radius: 20px;
        min-width: 100px;
        background: #00a8b0;
        border-color: #00a8b0;
        color: #fff;
      }
      button.ant-btn:hover {
        color: #fff;
        background: #a1ab36;
        border-color: #a1ab36;
      }
      button.finishBtn {
        border-radius: 20px;
        min-width: 100px;
        background: #a1ab36;
        border-color: #a1ab36;
        color: #fff;
      }
    }
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: #a1ab36;
    }
    .moderatorsFooter {
      text-align: right;
      margin-top: 25px;
      margin-bottom: 5px;
      .ant-btn {
        margin: 0 0 0 10px;
        min-width: 100px;
        border-radius: 20px;
      }
    }
  }
  .teamActiveRow {
    text-align: left;
    .ant-radio-button-wrapper {
      line-height: 30px !important;
    }
    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #fff;
      background: #a1ab36;
      border-color: #a1ab36;
    }
    .ant-radio-button-wrapper {
      color: #fff;
      background: #00a8b0;
      border: 1px solid #00a8b0;
    }
  }
  .timekepers {
    background: #e8eacd;
    padding: 15px;
    thead.ant-table-thead {
      display: table-header-group;
    }
    .ant-table table {
      border-spacing: 0 1px;
    }
    .penaltyInput {
      input {
        background-color: #c0c67e;
        border: 0;
      }
      textarea.ant-input {
        height: 20px;
      }
    }
    .timekepersFooter {
      text-align: right;
      margin-top: 10px;
      .ant-btn {
        margin: 0 0 0 10px;
        min-width: 100px;
        border-radius: 20px;
      }
    }
    .ant-select {
      width: 100%;
      margin-bottom: 0;
    }
    .PenaltiesRow {
      display: flex;
      flex-flow: row wrap;
      border-bottom: 1px solid #dfe1cb;
      padding: 7px 0;
      min-height: 38px;
      align-items: center;
      .PenaltiesRowCol {
        width: 50%;
      }
      .PenaltiesRowCol3 {
        width: 33.33%;
      }
      .PenaltiesRowCol3:nth-child(1) {
        width: 35%;
      }
      .PenaltiesRowCol3:nth-child(2) {
        width: 35%;
      }
      .PenaltiesRowCol3:nth-child(3) {
        width: 20%;
        text-align: center;
      }
      .PenaltiesRowCol3:nth-child(4) {
        width: 10%;
        text-align: center;
      }
      strong {
        font-weight: 500;
      }
      .ant-picker {
        width: 100%;
        max-width: 200px;
      }
      .ant-input-number {
        width: 100%;
        max-width: 200px;
      }
      input[type="number"] {
        width: 100%;
        max-width: 200px;
      }
      &.timekeeperComment {
        .PenaltiesRowCol {
          width: 100%;
        }
      }
      p {
        margin: 0;
      }
      .ant-input[disabled] {
        min-height: auto;
      }
    }
    .addPenalties {
      margin: 15px 0;
      border-radius: 20px;
    }
    .CloseBtn {
      cursor: pointer;
    }
    .ant-radio-disabled + span {
      color: rgba(0, 0, 0, 0.5);
    }
    .ant-checkbox-disabled + span {
      color: rgba(0, 0, 0, 0.5);
      cursor: not-allowed;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      color: rgba(0, 0, 0, 0.5);
    }
    .ant-picker-input > input[disabled] {
      color: rgba(0, 0, 0, 0.5);
    }
    span.textInfo {
      color: #a3a3a3;
    }
  }
  .moderators {
    thead.ant-table-thead {
      display: table-header-group;
    }
    .ant-table table {
      border-spacing: 0 1px;
    }
    .ant-tabs-nav-list .ant-tabs-tab {
      background: #00a8b0;
      margin: 0px 1px 0 0px;
      padding: 10px;
      color: #fff;
      min-width: 100px;
      text-align: center;
      display: block;
      &.ant-tabs-tab-active {
        background: #a1ab36;
      }
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff;
      text-shadow: none;
    }
    .ant-tabs-ink-bar {
      background: #a1ab36;
    }
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
      color: #fff;
    }
    .ant-input[disabled] {
      color: rgba(0, 0, 0, 0.5);
      min-height: 109px;
    }
    .itemDisplay {
      .TabsRow {
        .TabsCol {
          width: 100%;
          .moderatorsComment {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
    .TabsRow {
      display: flex;
      flex-flow: row wrap;
      .TabsCol {
        width: 50%;
        &.customStyle {
          &:first-child {
            width: 70%;
          }
          &:last-child {
            width: 30%;
          }
        }
        .ant-table-wrapper {
          margin-bottom: 30px;
        }
      }
    }
    .reviewBox {
      font-weight: 500;
    }
    .moderatorsComment {
      margin: 0 0px 0 0;
      padding: 0 0px 0 15px;
      textarea {
        min-height: 109px;
      }
    }
    .moderatorsFooter {
      text-align: right;
      margin-top: 25px;
      margin-bottom: 5px;
      .ant-btn {
        margin: 0 0 0 10px;
        min-width: 100px;
        border-radius: 20px;
      }
    }
  }
  .headJudges {
    thead.ant-table-thead {
      display: table-header-group;
    }
    .ant-table table {
      border-spacing: 0 1px;
    }
    .ant-tabs-nav-list .ant-tabs-tab {
      background: #00a8b0;
      margin: 0px 1px 0 0px;
      padding: 10px;
      color: #fff;
      &.ant-tabs-tab-active {
        background: #a1ab36;
      }
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff;
      text-shadow: none;
    }
    .ant-tabs-ink-bar {
      background: #a1ab36;
    }
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
      color: #fff;
    }
    .ant-input[disabled] {
      color: rgba(0, 0, 0, 0.5);
      min-height: 109px;
    }
    .TabsRow {
      display: flex;
      flex-flow: row wrap;
      .TabsCol {
        width: 50%;
        &.customStyle {
          &:first-child {
            width: 70%;
          }
          &:last-child {
            width: 30%;
          }
        }
        .ant-table-wrapper {
          margin-bottom: 30px;
        }
      }
    }
    .reviewBox {
      font-weight: 500;
    }
    .moderatorsComment {
      margin: 0 0px 0 0;
      padding: 0 0px 0 15px;
      textarea {
        min-height: 109px;
      }
    }
    .moderatorsFooter {
      text-align: right;
      margin-top: 25px;
      margin-bottom: 5px;
      .ant-btn {
        margin: 0 0 0 10px;
        min-width: 100px;
        border-radius: 20px;
      }
    }
  }
  @include breakpoint($desktop) {
    .ant-col {
      margin-bottom: 0;
    }
  }
  @include breakpoint($tablet) {
    .ant-col {
      margin-bottom: 15px;
    }
  }
  @include breakpoint($mobileOnly) {
    .ant-col {
      margin-bottom: 15px;
    }
  }
  .eventTeamsPoolList {
    .teamsList {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        border-bottom: 1px solid #dfe1cb;
        a {
          display: block;
          padding: 7px;
          color: #000;
          &:hover {
            background: rgba(0, 0, 0, 0.04);
          }
          span {
            color: #a1ab36;
          }
        }
      }
    }
    thead.ant-table-thead {
      display: none;
    }
    .ant-table .ant-table-tbody > tr > td.poolName:before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 1px;
      height: 1.6em;
      background-color: rgba(0, 0, 0, 0.06);
      transform: translateY(-50%);
      transition: background-color 0.3s;
      content: "";
    }
  }
  .poolName a {
    padding-right: 30px;
  }
  .userHide {
    display: none;
  }
  .scoreCircle {
    display: flex;
    border: 5px solid #a1ab36;
    border-radius: 50%;
    height: 176px;
    width: 176px;
    margin: 0 auto 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #399344;
    input[type="number"] {
      height: 60px;
      width: 145px;
      font-size: 36px;
      color: #a1ab36;
      background: transparent;
      border: 0;
      border-bottom: 2px solid;
      padding-left: 25px;
      text-align: center;
      &::-webkit-input-placeholder {
        font-size: 16px;
      }
      &::-moz-placeholder {
        font-size: 16px;
      }
      &:-ms-input-placeholder {
        font-size: 16px;
      }
      &:-moz-placeholder {
        font-size: 16px;
      }
    }
  }
  .scoreCircle2 {
    display: flex;
    border: 5px solid #a1ab36;
    border-radius: 50%;
    height: 176px;
    width: 176px;
    margin: 0 auto 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #399344;
    .ant-input-number {
      width: 100%;
      background-color: transparent;
      width: auto;
      border: 0px solid #d9d9d9;
      border-radius: 0;
      input[type="number"] {
        height: 60px;
        width: 145px;
        font-size: 36px;
        color: #a1ab36;
        background: transparent;
        border: 0;
        border-bottom: 2px solid;
        padding-left: 10px;
        text-align: center;
        &::-webkit-input-placeholder {
          font-size: 16px;
        }
        &::-moz-placeholder {
          font-size: 16px;
        }
        &:-ms-input-placeholder {
          font-size: 16px;
        }
        &:-moz-placeholder {
          font-size: 16px;
        }
      }
    }
  }
  span.closeOutlined {
    display: block;
    text-align: center;
    cursor: pointer;
  }
  input[type="number"]::-webkit-input-placeholder {
    color: #888;
  }
  input[type="number"]:-ms-input-placeholder {
    color: #888;
  }
  input[type="number"]::placeholder {
    color: #888;
  }
  .ant-tabs.ant-tabs-left {
    .ant-tabs-nav-list {
      height: 200px;
    }
  }
  .editBtn {
    position: absolute;
    z-index: 2;
    margin: 10px;
    background: transparent;
    border: 0;
    padding: 0;
    color: #000;
    span.anticon.anticon-edit {
      font-size: 18px;
    }
  }
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.5);
  }
  .ant-input-number-disabled {
    color: rgba(0, 0, 0, 0.5);
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0px;
  }
  .previewScreen .ant-checkbox {
    display: none;
  }
  .previewScreen .ant-checkbox-wrapper span {
    margin: 0;
    padding: 0;
  }
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none;
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .commentsListBox {
    margin: 20px 0;
    padding: 0;
    p {
      margin: 0;
      padding: 0;
    }
    ul {
      margin: 0px 0 15px;
      padding: 0;
      list-style: none;
      li {
        border-bottom: 1px solid #d7d9bc;
        padding: 10px 0;
        strong {
          font-size: 13px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }

  .itemDisplay {
    display: initial;
  }
  .itemNotDisplay {
    display: none;
  }
  .hideText {
    display: none;
  }
  .showText {
    display: flex;
  }
  .previewStatus {
    span {
      margin: 0;
      padding: 0;
      &.ant-checkbox {
        display: none;
      }
    }
  }
  span.submittedStatus {
    position: absolute;
    top: 0px;
    right: 0;
    font-weight: 500;
    display: inline-block;
    padding: 10px;
  }

  .mehiRow {
    display: flex;
    margin-bottom: 30px;
  }
  .mehiRow input {
    width: 91%;
    border: 0;
    border-radius: 0;
    height: 40px;
  }
  .mehiRow button {
    border: 0;
    border-radius: 0;
    height: 40px;
    margin: 0;
    width: 9%;
  }
}
