@import "../styles/colours";

.login-page {
  background-image: url("../assets/images/TM_Background_01.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  .loginPageContent {
    width: 100%;
    color: $white;
    max-width: 400px;
    margin: 0 auto;
    .form-group {
      margin-bottom: 15px;
    }
    h5 {
      color: $white;
    }
    input[type="text"],
    input[type="password"],
    input[type="number"],
    textarea {
      -webkit-appearance: none;
      height: 40px;
      border-radius: 50px;
      text-align: center;
    }
    .loginBtn {
      -webkit-appearance: none;
      height: 40px;
      border-radius: 50px;
      text-align: center;
      width: 200px;
      margin-top: 15px;
      font-weight: 600;
    }
    .ant-input-suffix {
      margin-left: 4px;
      position: absolute;
      right: 10px;
      top: 14px;
    }
    .ant-input-affix-wrapper {
      background-color: transparent;
      border: 0px solid #d9d9d9;
      padding: 0;
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border-color: #40a9ff;
      border-right-width: 0px !important;
      outline: 0;
      box-shadow: none;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: #40a9ff;
      border-right-width: 0px !important;
      z-index: 1;
    }
    .has-error {
      position: relative;
    }
    .help-block {
      position: absolute;
      bottom: -20px;
      text-align: center;
      width: 100%;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
    }
  }
  .submitRow {
    position: relative;
    input[type="submit"] {
      background: #00a8b0;
      border-color: #00a8b0;
      border: 0;
      cursor: pointer;
      &:hover {
        color: #fff;
        background: #a1ab36;
        border-color: #a1ab36;
      }
    }
    .loadingSpin {
      position: absolute;
      left: 0;
      right: 0;
      font-size: 25px;
      color: #ffffff;
      top: 16px;
    }
  }
}
.resetPassword {
  background-color: #e8eacd;
  padding: 30px 30px 10px;
  border-radius: 15px;
  max-width: 500px;
  margin: 0 auto;
  .resetBtn {
    text-align: right;
    button {
      border-radius: 50px;
    }
  }
  .ant-input-affix-wrapper {
    border-radius: 50px;
  }
  input[type="text"],
  input[type="password"],
  input[type="number"],
  textarea {
    height: 30px;
  }
  .forgetPasswordBtn {
    background: #00a8b0;
    border: 0;
    cursor: pointer;
    border-radius: 50px;
    min-width: 100px;
  }
}
a.login-form-forgot {
  color: #fff;
  &:hover {
    color: #fff;
  }
}
