@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i');

html, body, h1, h2, h3, h4, h5, h6, p, span {
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 34px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 21px;
}

html {
  font-size: 16px;
  line-height: 20px
}

.text-italic {
  font-style: italic;
}

.text-center {
  text-align: center;
}