@import "./typography";
@import "./toastr";
@import "./common";
@import "./colours";
@import "./flex";
@import "./responsive";
// @import "./iecompatibility";

.page-body {
  background-image: url("../assets/images/TM_Background_01.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  .ant-layout {
    background: none;
  }
  .ant-layout-header {
    height: 50px;
    padding: 0 50px;
    color: $grey-5;
    line-height: 49px;
    background: none;
    @include breakpoint($tablet) {
      padding: 0 15px;
    }
    @include breakpoint($mobileOnly) {
      padding: 0 10px;
    }
  }
  .ant-layout-content {
    min-height: calc(100vh - (100px));
    padding: 50px !important;
    width: 100%;
    max-width: 975px;
    margin: 0 auto;
    position: relative;
    @include breakpoint($tablet) {
      padding: 0 15px !important;
    }
    @include breakpoint($mobileOnly) {
      padding: 0 10px !important;
    }
  }
  .ant-layout-footer {
    padding: 0px 50px;
    background: transparent;
    max-width: 975px;
    width: 100%;
    margin: 0 auto 10px;
    position: relative;
    .footerLogo img {
      max-width: 200px;
      position: absolute;
      bottom: 0;
      right: 50px;
    }
  }
  .ant-menu {
    background: none;
  }
  .ant-menu-horizontal {
    border-bottom: 0px solid $grey-5;
    align-items: center;
    justify-content: center;
    .ant-menu-item {
      margin: 10px 5px !important;
      padding: 0 !important;
      a {
        color: #fff;
        background-color: $secondary-teal;
        min-width: 110px;
        display: block;
        text-align: center;
        line-height: 36px;
        border-radius: 40px;
        padding: 0 10px;
        &:hover {
          color: $grey-5;
          background-color: #a1ab36;
        }
      }
    }
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    a {
      color: $grey-5;
      background-color: #a1ab36;
    }
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 0px solid #ffffff;
  }
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: 0px solid transparent;
  }
  .ant-table {
    background: $Third-green;
    border-radius: 20px 20px 0 0;
    a {
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .ant-table-thead > tr > th {
    background: $secondary-green;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: rgba(0, 0, 0, 0.04);
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: $secondary-green;
  }
  td.ant-table-column-sort {
    background: #e8eacd;
  }
  .ant-table-filter-trigger {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-table-column-sorter {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-table-filter-trigger.active {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-pagination-item-active:focus-visible a,
  .ant-pagination-item-active:hover a {
    color: #ffffff;
  }
  .ant-pagination-item:focus-visible,
  .ant-pagination-item:hover {
    border-color: #d0d59a !important;
  }
  .ant-pagination-item-active {
    background: #a1ab36 !important;
    border-color: #a1ab36 !important;
  }
  .ant-pagination-item-active a {
    color: #fff;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.85);
    border-color: #d0d59a;
    cursor: not-allowed;
  }
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #fff;
    border-color: #d0d59a;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: #d0d59a;
    border: 1px solid #d0d59a;
  }

  .ant-pagination-item {
    background-color: #d0d59a;
    border: 1px solid #d0d59a;
  }
  .ant-pagination-item:focus-visible a,
  .ant-pagination-item:hover a {
    color: #ffffff;
  }
  .ant-btn-primary {
    background: #00a8b0;
    border-color: #00a8b0;
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: #fff;
    background: #a1ab36;
    border-color: #a1ab36;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 20px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 20px;
  }
  .ant-tabs-tab:hover {
    color: #fff;
    background: #a1ab36;
  }
  .ant-breadcrumb {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 7px 7px;
    margin-bottom: 30px;
  }
  .ant-breadcrumb-separator {
    color: #fff;
  }
  .ant-breadcrumb-link {
    color: #fff;
    cursor: pointer;
  }
  .ant-breadcrumb a:hover {
    color: #fff;
  }
  .ant-breadcrumb > span:last-child {
    .ant-breadcrumb-link {
      cursor: text;
      color: #a1ab36;
    }
  }
}
.mt-3 {
  color: #fff !important;
}
.logo {
  height: 50px;
  padding: 5px 0;
}
.ant-modal {
  p {
    margin: 10px 0;
    b,
    strong {
      font-weight: 500;
    }
  }
  .ant-picker {
    width: 100%;
  }
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-header {
    border-bottom: 0px solid #f0f0f0;
    border-radius: 20px 20px 0 0;
    background: #d0d59a;
  }
  .ant-modal-footer {
    border-top: 0px solid #f0f0f0;
    border-radius: 20px;
    button.ant-btn.ant-btn-primary {
      border-radius: 20px;
      background: #00a8b0;
      border-color: #00a8b0;
    }
    button.ant-btn {
      border-radius: 20px;
      background: #fff;
    }
    .ant-btn:hover,
    .ant-btn:focus {
      color: #a1ab36;
      border-color: #a1ab36;
    }
    button.ant-btn.ant-btn-primary:hover,
    button.ant-btn.ant-btn-primary:focus {
      border-color: #a1ab36;
      background: #a1ab36;
      color: #fff;
    }
  }
  input[type="text"],
  input[type="password"],
  input[type="number"] {
    -webkit-appearance: none;
    border-radius: 20px;
    height: 36px;
  }
  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
    min-width: 100px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px;
    height: auto;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 34px;
  }
  .ant-modal-close {
    min-width: auto !important;
  }
  .buttonDisabled {
    cursor: not-allowed;
    background: rgba(0, 168, 176, 0.7) !important;
    border-color: rgba(0, 168, 176, 0.3) !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }
}
.ant-popover .ant-btn {
  min-width: 65px;
  border-radius: 20px;
  margin: 1px;
  text-shadow: none;
  background-color: #ffffff;
  border-color: #a1ab36;
  color: #a1ab36;
}
.ant-popover .ant-btn:hover {
  background-color: #a1ab36;
  border-color: #a1ab36;
  color: #fff;
}
.redMandatory {
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  display: inline-block;
  position: relative;
  top: -1px;
  right: 2px;
}
.errorMsg {
  color: #ff4d4f;
}
.headingName {
  position: absolute;
  color: #fff;
  width: calc(100% - 100px);
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5px 5px;
  font-weight: 500;
  margin: 0 auto;
}
