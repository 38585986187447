@import "../../styles/responsive";

.disciplineInfo {
  .ant-col {
    margin-bottom: 15px;
  }
  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .userShow {
    display: table-row;
  }
  .userHide {
    display: none;
  }
  .userActiveRow {
    text-align: right;
    .ant-radio-button-wrapper {
      line-height: 30px !important;
    }
  }
  @include breakpoint($desktop) {
    .ant-col {
      margin-bottom: 15px;
    }
  }
  @include breakpoint($tablet) {
    .ant-col {
      margin-bottom: 100px;
    }
  }
  @include breakpoint($mobileOnly) {
    .ant-col {
      margin-bottom: 100px;
    }
  }
}
.ant-form-item-label > label {
  font-weight: 600;
}
.nameLink {
  cursor: pointer;
}
.fieldHide {
  display: none !important;
}
.fieldShow {
  display: block;
}
