@import "../../styles/responsive";

.eventsInfo {
  .ant-col {
    margin-bottom: 15px;
  }

  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .eventActiveRow {
    text-align: right;

    .ant-radio-button-wrapper {
      line-height: 30px !important;
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #fff;
      background: #a1ab36;
      border-color: #a1ab36;
    }

    .ant-radio-button-wrapper {
      color: #fff;
      background: #00a8b0;
      border: 1px solid #00a8b0;
    }
  }

  .rowDelete {
    display: block;
    text-align: center;
    font-size: 16px;
    color: #dddddd;
  }

  @include breakpoint($desktop) {
    .ant-col {
      margin-bottom: 15px;
    }
  }

  @include breakpoint($tablet) {
    .ant-col {
      margin-bottom: 15px;
    }
  }

  @include breakpoint($mobileOnly) {
    .ant-col {
      margin-bottom: 15px;
    }

    thead.ant-table-thead {
      display: none;
    }

    .ant-table-tbody>tr>td {
      width: 100%;
      display: block;
      padding-left: 25%;
    }

    td.ant-table-cell:nth-child(3n + 1):before {
      content: "Name";
      padding: 0 5px;
      margin-right: 5px;
      position: absolute;
      left: 0;
      font-weight: 600;
    }

    td.ant-table-cell:nth-child(3n + 2):before {
      content: "Email";
      padding: 0 5px;
      margin-right: 5px;
      position: absolute;
      left: 0;
      font-weight: 600;
    }

    td.ant-table-cell:nth-child(3n + 3):before {
      content: "Admin";
      padding: 0 5px;
      margin-right: 5px;
      position: absolute;
      left: 0;
      font-weight: 600;
    }

    td.ant-table-cell:nth-child(3n + 3) {
      border-bottom: 15px solid #f2f2f2;
    }
  }

  .userShow {
    display: table-row;
  }

  .userHide {
    display: none;
  }

  label.ant-checkbox-wrapper.disciplineListPosition span.ant-checkbox {
    display: none;
  }

  label.ant-checkbox-wrapper.disciplineListPosition span {
    display: block;
    text-align: center;
    width: 100%;
    padding: 2px;
  }

  .addEventList {
    thead tr th:last-child {
      text-align: center;
    }

    .EditOutlinedAction {
      display: block;
      text-align: center;
    }
  }
}

.ant-form-item-label>label {
  font-weight: 600;
}

.addTeamModal {
  .ant-modal-header {
    padding: 0px 0px;
    border-bottom: 0px solid #f0f0f0;
  }

  .modalHeadingSteps {
    background-color: #e8eacd;
    border-radius: 20px 20px 0 0;

    .ant-steps-item-icon {
      display: none;
    }

    .ant-steps-item-title::after {
      display: none;
    }

    .ant-steps-item {
      width: 50%;
      padding: 10px !important;
      text-align: center;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
      color: #fff;
    }

    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
      color: rgba(0, 0, 0, 0.45);
    }

    .ant-steps-item-active {
      background: #a1ab36;
    }

    .ant-steps-item-container:first-child {
      border-radius: 15px 0 0 0;
    }

    .ant-steps-item-container:last-child {
      border-radius: 0px 15px 0 0;
    }

    .ant-steps-item:first-child.ant-steps-item-active {
      border-radius: 15px 0 0 0;
    }

    .ant-steps-item:last-child.ant-steps-item-active {
      border-radius: 0 15px 0 0;
    }
  }

  .teamsTab {
    thead.ant-table-thead tr th:last-child {
      text-align: center;
    }

    .rowDelete {
      display: block;
      text-align: center;
    }
  }

  .ant-radio-group {
    width: 100%;
  }

  .ant-space {
    width: 100%;
  }

  .ant-space-item {
    display: block;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    padding: 5px 0 15px;

    &:last-child {
      border-bottom: 0px solid #f2f2f2;
    }
  }

  .ant-checkbox-wrapper {
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 0 10px;
  }

  .ant-checkbox-group {
    display: block;
  }

  .ant-checkbox-group-item {
    margin: 0;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    padding: 12px 0 12px;

    &:last-child {
      border-bottom: 0px solid #f2f2f2;
    }
  }

  .ant-select {
    display: block;
  }
}

.eventUsersModal {
  .ant-select {
    display: block;
  }
}

.finalizedTeamModal {
  .ant-table {
    background: #f7f7f7;
    border-radius: 20px 20px 0 0;
  }

  .ant-table-thead>tr>th {
    // background: #d0d59a;
    background: rgba(208, 213, 154, 0.5);
  }

  .ant-table-tbody>tr.ant-table-row:hover>td {
    background: rgba(0, 0, 0, 0.04);
  }
}

.tabStyle {
  .buttonDisabled {
    cursor: not-allowed;
    background: rgba(0, 168, 176, 0.7) !important;
    border-color: rgba(0, 168, 176, 0.3) !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }

  .ant-tabs-tab {
    background: #00a8b0;
    color: #fff;
    font-weight: 600;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background: #a1ab36;
  }

  .ant-tabs-ink-bar {
    background: #00a8b0;
  }

  .ant-tabs-nav {
    margin-top: 47px !important;
  }

  .scoreBoard {
    margin: 0px 0 0 0;

    .ant-collapse {
      background-color: transparent;
      border: 0px solid #d9d9d9;
    }

    .ant-collapse.poolAccordion {
      .ant-collapse-item {
        border-bottom: 1px solid #d0d59a;
        background: #d0d59a;
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 15px;

        .ant-collapse-content {
          background-color: #e8eacd;
        }
      }
    }

    .ant-collapse.teamAccordion {
      .ant-collapse-item {
        border-bottom: 0px solid rgba(208, 213, 154, 0.8);
        background: rgba(208, 213, 154, 0.8);
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 14.5px;

        .ant-collapse-content {
          background-color: #f2f2f2;
        }
      }
    }

    .ant-collapse.disciplineAccordion {
      .ant-collapse-item {
        border-bottom: 0px solid rgba(208, 213, 154, 0.5);
        background: rgba(208, 213, 154, 0.5);
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 14px;

        .ant-collapse-content {
          background-color: #f7f7f7;
          font-weight: 400;
        }
      }
    }

    .ant-collapse-extra {
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
    }

    .ant-table {
      background: #ffffff;
      border-radius: 0;
    }

    .ant-table-container table>thead>tr:first-child th:last-child {
      border-top-right-radius: 0;
    }

    .ant-table-container table>thead>tr:first-child th:first-child {
      border-top-left-radius: 0;
    }

    .ant-table-thead>tr>th {
      background: #fff;
    }

    .ant-table-tbody>tr.ant-table-row:hover>td {
      background: #fff;
    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    textarea {
      -webkit-appearance: none;
      border: 2px solid #e1e4c6;
      padding-left: 10px;
    }

    span.scoreAccordianBtn {
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      border: 2px solid #bcc08c;
      padding: 5px 15px;
      border-radius: 20px;
      background-color: #bcc08c;
      color: #494b3a;
      margin-left: 10px;
    }

    span.scoreAccordianBtn:hover {
      background-color: #919574;
    }

    span.movePool {
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      border: 2px solid #bcc08c;
      padding: 5px 15px;
      border-radius: 20px;
      background-color: #bcc08c;
      color: #494b3a;
      margin-left: 10px;
    }

    span.movePool:hover {
      background-color: #919574;
    }

    .PSL-Row {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      width: 100%;
      border: 1px solid #e1e4c6;
      position: relative;
      padding: 0 7px;

      .PSL-Col2 {
        width: 50%;
        padding: 7px;
        position: relative;

        &:last-child {
          text-align: center;
        }

        &:last-child:before {
          position: absolute;
          content: "";
          border-left: 1px solid #e1e4c6;
          left: 0;
          top: 0;
          bottom: 0;
        }
      }

      .PSL-Col3 {
        width: 33.33%;
        padding: 7px;
        position: relative;

        &:last-child {
          text-align: center;
        }

        &:last-child:before {
          position: absolute;
          content: "";
          border-left: 1px solid #e1e4c6;
          left: 0;
          top: 0;
          bottom: 0;
        }

        &:nth-child(2) {
          padding-left: 7px;
          text-align: center;
        }

        &:nth-child(2):before {
          position: absolute;
          content: "";
          border-left: 1px solid #e1e4c6;
          left: 0;
          top: 0;
          bottom: 0;
        }
      }
    }
  }
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 !important;
  border-bottom: 2px solid #e8eacd !important;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab {
  border-bottom: 2px solid #e8eacd !important;
}

.ant-tabs-left>.ant-tabs-content-holder,
.ant-tabs-left>div>.ant-tabs-content-holder {
  margin-left: 0 !important;
  border-left: 0px solid #f0f0f0 !important;
}

.buttonRow {
  margin-bottom: 15px;
  text-align: right;

  .startEvent {
    margin-right: 2px;
  }
}

.ant-select {
  margin-bottom: 10px;
}

.ant-modal {
  .modalForm p {
    margin: 20px 0 5px 0;
  }
}

.usersTab .ant-table-tbody>tr>td span:last-child span {
  display: none;
}

.reportDownloadRow {
  margin: 0 0 5px;
  text-align: right;

  button {
    margin-left: 5px;
  }
}

.ant-collapse.reportsTabPanel .ant-collapse-item-disabled>.ant-collapse-header,
.ant-collapse.reportsTabPanel .ant-collapse-item-disabled>.ant-collapse-header>.arrow {
  color: rgba(0, 0, 0, 0.85);
  cursor: default;
}

.showJudgeTeams {
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid #bcc08c;
  padding: 5px 15px;
  border-radius: 20px;
  background-color: #bcc08c;
  color: #494b3a;
  margin-left: 10px;

  &:hover {
    background-color: #919574;
  }
}

.judgeTeamsList {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: 7px 0;
      margin: 0;
      border-bottom: 1px solid #f2f2f2;
    }
  }
}

span.JudgeEdit {
  float: right;
  cursor: pointer;
}

.disabled-row {
  // pointer-events: none;
  background-color: #d2d3ba;
  opacity: 0.5;
}

.usersTab table tr th,
.usersTab table tr td {
  max-width: 300px;
}

.ant-drawer-header {
  background-color: #d0d59a !important;
  padding: 15px 0 !important;
}

.ant-drawer-body {
  p {
    margin: 0;
  }
}